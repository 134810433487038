@keyframes scaling {
  0%,
  100% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.2);
  }
}

.scale-up-anim-1 {
  animation: scaling 1.5s ease-in-out infinite;
  animation-delay: 0s;
}

.scale-up-anim-2 {
  animation: scaling 1.5s ease-in-out infinite;
  animation-delay: 0.2s;
}

.scale-up-anim-3 {
  animation: scaling 1.5s ease-in-out infinite;
  animation-delay: 0.4s;
}

.scale-up-anim-4 {
  animation: scaling 1.5s ease-in-out infinite;
  animation-delay: 0.6s;
}

.scale-up-anim-5 {
  animation: scaling 1.5s ease-in-out infinite;
  animation-delay: 0.8s;
}
